import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Box } from "@mui/material";
import Navigation from "./Navigation";
import Home from "./Home";
import About from "./About";
import Login from "./Login";
import Signup from "./Signup";
import Pricing from "./Pricing";
import Apply from "./pages/Apply";
import HowitWorks from "./HowitWorks";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SchedulerContainer from "./pages/SchedulerContainer";
import AppointmentPage from "./pages/AppointmentPage";
import PatientPage from "./pages/PatientPage";
import SideDrawer from "./components/SideDrawer";
import { createClient } from "@supabase/supabase-js";
import MessagePage from "./pages/MessagePage";
import SettingsPage from "./pages/SettingsPage";
import NewPatientForm from "./pages/NewPatientForm";
import FileUploadPage from "./pages/FileUploadPage";
import Admin from "./pages/admin";
import DentalScheduler from "./pages/DentalScheduler";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <Router>
      <ToastContainer />
      {session ? <SideDrawer /> : <Navigation />}
      {session ? (
        <Box sx={{ display: "flex" }}>
          <Box component="main" sx={{ flexGrow: 1, pt: 8, marginLeft: "58px" }}>
            <Routes>
              <Route path="/" element={<Navigate to="/calendar" replace />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/apply" element={<Apply />} />
              <Route path="/howitworks" element={<HowitWorks />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/calendar" element={<SchedulerContainer />} />
              <Route path="/appointments" element={<AppointmentPage />} />
              <Route path="/patients" element={<PatientPage />} />
              <Route path="/messages" element={<MessagePage />} />
              <Route path="/setting" element={<SettingsPage />} />
              <Route path="/fileuploadpage" element={<FileUploadPage />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/scheduler" element={<DentalScheduler />} />
            </Routes>
          </Box>
        </Box>
      ) : (
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/howitworks" element={<HowitWorks />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/newPatientForm" element={<NewPatientForm />} />
          {/* <Route path="/calendar" element={<SchedulerContainer />} />
          <Route path="/appointments" element={<AppointmentPage />} />
          <Route path="/patients" element={<PatientPage />} />
          <Route path="/messages" element={<MessagePage />} /> */}
        </Routes>
      )}
    </Router>
  );
}

export default App;
